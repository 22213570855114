// Colors

// The color palette
$palette: (
  light: (
    1: #ffffff,
    2: #797979,
    3: #414141,
  ),
  dark: (
    1: #151719,
    2: #25282c,
    3: #33363a,
  ),
  primary: (
    1: #ff8300,
    2: #9ee61f,
    3: #92cd28,
    4: #9b5e5e,
  ),
  secondary: (
    1: #ffa33f,
    2: #e4ff7f,
    3: #92cd28,
    4: #d2f9ee,
  ),
  alert: (
    error: #ff6171,
    warning: #ff8300,
    success: #24e5af,
  ),
);

// Base typography: color
$color: (
  heading: get-color(dark, 1),
  base: get-color(dark, 2),
  high-contrast: get-color(dark, 1),
  mid-contrast: get-color(dark, 2),
  low-contrast: get-color(dark, 3),
  primary: get-color(primary, 3),
  secondary: get-color(secondary, 1),
  error: get-color(alert, error),
  warning: get-color(alert, warning),
  success: get-color(alert, success),
  // ↓ Inverted colors
  heading-inverse: get-color(light, 1),
  base-inverse: get-color(light, 3),
  high-contrast-inverse: get-color(light, 1),
  mid-contrast-inverse: get-color(light, 2),
  low-contrast-inverse: get-color(light, 3),
);

// Borders and dividers: color
$border--color: (
  divider: get-color(dark, 2),
  divider-inverse: get-color(light, 1),
);

// Icons: fill color
$icon--color: (
  hamburger: get-color(light, 2),
  // hamburger icon
  accordion: get-color(primary, 1),
  // accordion icon
  social: get-color(primary, 1),
  // social icons
  social-hover: get-color(primary, 2),
  // social icons (:hover)
  modal: get-color(light, 3),
  // modal icon
  modal-hover: get-color(light, 2),
  // modal icon (:hover)
  news-more: get-color(primary, 2),
  // news - read more link arrow
  // ↓ Inverted colors
  hamburger-inverse: get-color(dark, 2),
  // hamburger icon
  accordion-inverse: get-color(primary, 1),
  // accordion icon
  social-inverse: null,
  // social icons
  social-hover-inverse: null
    // social icons (:hover),,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

// Misc elements: background color
$bg--color: (
  body: get-color(light, 1),
  body-shadow: 0 20px 48px rgba(get-color(light, 1), 0.8),
  // body shadow when a boxed layout is used (set to null if don't want a shadow)
  body-outer: lighten(get-color(light, 1), 2%),
  // outer bg color when a boxed layout is used
  bg-color: lighten(get-color(dark, 1), 9%),
  // .has-bg-color helper class
  shadow: 0 24px 64px rgba(get-color(light, 1), 0.64),
  // .has-shadow helper class
  code: darken(get-color(light, 1), 3%),
  code-inverse: darken(get-color(light, 1), 3%),
  check-checked-inverse: darken(get-color(light, 1), 3%),
);
